var DetailToolbarHelper = (function () {

  return {
    registerOnDropdownShow: function () {
      $('.dropdown').off('show.bs.dropdown', dropDownShowHandler).on('show.bs.dropdown', dropDownShowHandler);
    },
    registerOnDropdownHide: function () {
      $('.dropdown').off('hidden.bs.dropdown', dropDownHideHandler).on('hidden.bs.dropdown', dropDownHideHandler);
    },
    unregisterOnDropdownShow: function () {
      $('.dropdown').off('show.bs.dropdown', dropDownShowHandler);
    },
    unregisterOnDropdownHide: function () {
      $('.dropdown').off('hidden.bs.dropdown', dropDownHideHandler);
    }
  }

  function dropDownShowHandler() {
    $('.detailtoolbar').css('overflow-x', 'visible');
  };

  function dropDownHideHandler() {
    if (window.matchMedia('(min-device-width:769px)').matches)
      $('.detailtoolbar').css('overflow-x', 'hidden');
    else
      $('.detailtoolbar').css('overflow-x', 'auto');
  };

})(DetailToolbarHelper || {})

var NavLinkHoverHelper = (function () {

  return {
    registerOnNavLinkTouch: function () {
      $('.navlink-hover').off('touchstart', navLinkTouchHandler).on('touchstart', navLinkTouchHandler);
    },
    unregisterOnNavLinkTouch: function () {
      $('.navlink-hover').off('touchstart', navLinkTouchHandler);
    }
  }

  function navLinkTouchHandler(e) {
    // remove hover on touch devices
    if ($(this).hasClass('navlink-hover')) {
      //$(this).removeClass('navlink-hover');
      $('.navlink-hover').each(function () {
        $(this).removeClass('navlink-hover');
      });
    }
  };

})(NavLinkHoverHelper || {})

var DropdownSubmenuHelper = (function () {

  return {
    registerOnClickHandler: function () {
      $('.dropdown-menu a.dropdown-toggle').off('click', subMenuClickHandler).on('click', subMenuClickHandler);
    },
    unregisterOnClickHandler: function () {
      $('.dropdown-menu a.dropdown-toggle').off('click', subMenuClickHandler);
    }
  }

  function subMenuClickHandler(e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
      $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
  };

})(DropdownSubmenuHelper || {})


